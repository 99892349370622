import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCheckboxInput, LazyCodeInput, LazyColorInput, LazyDateInput, LazyFileInput, LazyFlatSelectInput, LazyImageInput, LazyPhoneInput, LazyRatingInput, LazyRichTextAreaInput, LazyScaleInput, LazySelectInput, LazySignatureInput, LazySliderInput, LazyTextAreaInput, LazyTextInput, LazyToggleSwitchInput, LazyInputHelp, LazyInputLabel, LazyInputWrapper, LazyUploadedFile, LazyVCheckbox, LazyVSelect, LazyVSwitch, LazyUseFormInput, LazyAlert, LazyAlertError, LazyAlertSuccess, LazyHasError } from '#components'
const lazyGlobalComponents = [
  ["CheckboxInput", LazyCheckboxInput],
["CodeInput", LazyCodeInput],
["ColorInput", LazyColorInput],
["DateInput", LazyDateInput],
["FileInput", LazyFileInput],
["FlatSelectInput", LazyFlatSelectInput],
["ImageInput", LazyImageInput],
["PhoneInput", LazyPhoneInput],
["RatingInput", LazyRatingInput],
["RichTextAreaInput", LazyRichTextAreaInput],
["ScaleInput", LazyScaleInput],
["SelectInput", LazySelectInput],
["SignatureInput", LazySignatureInput],
["SliderInput", LazySliderInput],
["TextAreaInput", LazyTextAreaInput],
["TextInput", LazyTextInput],
["ToggleSwitchInput", LazyToggleSwitchInput],
["InputHelp", LazyInputHelp],
["InputLabel", LazyInputLabel],
["InputWrapper", LazyInputWrapper],
["UploadedFile", LazyUploadedFile],
["VCheckbox", LazyVCheckbox],
["VSelect", LazyVSelect],
["VSwitch", LazyVSwitch],
["UseFormInput", LazyUseFormInput],
["Alert", LazyAlert],
["AlertError", LazyAlertError],
["AlertSuccess", LazyAlertSuccess],
["HasError", LazyHasError],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
